// =============================================================================
// PRODUCTS PAGES LAYOUTS
// =============================================================================

export default {
  path: 'products',
  component: () => import('../../views/admin/products/index'),
  children: [
    {
      path: '',
      component: () => import('../../views/admin/products/productsList/index'),
      children: [
        {
          path: '',
          name: 'ProductsList',
          component: () => import(/* webpackChunkName: "ProductsList" */ '../../views/admin/products/productsList/list/productsList.vue'),
          meta: {
            pageTitle: 'لیست کالا و خدمت',
            pageTitleI18n: 'products.list.title'
          // rule: 'editor'
          }
        },
        {
          path: 'trash',
          name: 'ProductsTrash',
          component: () => import(/* webpackChunkName: "ProductsTrash" */ '../../views/admin/products/productsList/trash/productsTrash.vue'),
          meta: {
            pageTitle: 'سطل آشغال کالا و خدمت',
            pageTitleI18n: 'products.trash.title'
          // rule: 'editor'
          }
        },
        {
          path: 'prd-:id',
          component: () => import('../../views/admin/products/productsList/product/index.vue'),
          children: [
            {
              path: '',
              name: 'Product',
              component: () => import(/* webpackChunkName: "Product" */ '../../views/admin/products/productsList/product/menu/productMenu.vue'),
              meta: {
                pageTitle: 'کالا',
                pageTitleI18n: 'products.product.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'attributes',
              name: 'ProductAttributes',
              component: () => import(/* webpackChunkName: "ProductAttributes" */ '../../views/admin/products/productsList/product/attributes/productAttributes.vue'),
              meta: {
                pageTitle: 'ویژگی های کالا',
                pageTitleI18n: 'products.product.attributes.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'sale-turnover',
              name: 'ProductSaleTurnover',
              component: () => import(/* webpackChunkName: "ProductSaleTurnover" */ '../../views/admin/products/productsList/product/turnover/productSaleTurnover.vue'),
              meta: {
                pageTitle: 'گردش انبار کالا',
                pageTitleI18n: 'products.product.turnover.sale.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'warehouse-turnover',
              name: 'ProductWarehouseTurnover',
              component: () => import(/* webpackChunkName: "ProductWarehouseTurnover" */ '../../views/admin/products/productsList/product/turnover/productWarehouseTurnover.vue'),
              meta: {
                pageTitle: 'گردش انبار کالا',
                pageTitleI18n: 'products.product.turnover.storeroom.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'barcode',
              name: 'ProductBarcode',
              component: () => import(/* webpackChunkName: "ProductBarcode" */ '../../views/admin/products/productsList/product/barcodes/productBarcodes.vue'),
              meta: {
                pageTitle: 'بارکد کالا',
                pageTitleI18n: 'products.product.barcode.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'points',
              name: 'ProductPoints',
              component: () => import(/* webpackChunkName: "ProductPoints" */ '../../views/admin/products/productsList/product/points/productPoints.vue'),
              meta: {
                pageTitle: 'امتیاز کالا',
                pageTitleI18n: 'products.product.points.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'variants',
              name: 'ProductVariants',
              component: () => import(/* webpackChunkName: "ProductVariants" */ '../../views/admin/products/productsList/product/variants/productVariants.vue'),
              meta: {
                pageTitle: 'متغیر های کالا',
                pageTitleI18n: 'products.product.variants.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'activities-log',
              name: 'ProductActivitiesLog',
              component: () => import(/* webpackChunkName: "userActivitiesLog" */ '../../views/admin/products/productsList/product/activitiesLog/productActivitiesLog.vue'),
              meta: {
                permission: 'activity_logs.show',
                pageTitle: 'لاگ های کالا',
                pageTitleI18n: 'products.product.logs.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'edit',
              name: 'EditProduct',
              component: () => import(/* webpackChunkName: "EditProduct" */ '../../views/admin/products/productsList/edit/editProduct.vue'),
              meta: {
                pageTitle: 'ویرایش کالا',
                pageTitleI18n: 'products.edit.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            }
          ]
        },
        {
          path: 'insert',
          name: 'InsertProduct',
          component: () => import(/* webpackChunkName: "InsertProduct" */ '../../views/admin/products/productsList/insert/insertProduct.vue'),
          meta: {
            pageTitle: 'افزودن کالا',
            pageTitleI18n: 'products.insert.title'
          // rule: 'editor'
          }
        }
      ]
    },
    {
      path: 'categories',
      component: () => import(/* webpackChunkName: "categories" */ '../../views/admin/products/categories/categories.vue'),
      name: 'categories',
      meta: {
        pageTitle: 'لیست دسته بندی ها',
        pageTitleI18n: 'categories.list.title'
      // rule: 'editor'
      }
    },
    {
      path: 'attributes',
      component: () => import(/* webpackChunkName: "attributes" */ '../../views/admin/products/Attributes/list/AttributesList.vue'),
      name: 'attributes',
      meta: {
        pageTitle: 'لیست ویژگی ها',
        pageTitleI18n: 'attributes.list.title'
      // rule: 'editor'
      }
    }
  ]
}
