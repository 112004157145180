// =============================================================================
// ACCOUNTANCY PAGES LAYOUTS
// =============================================================================

export default {
  path: 'accountancy',
  component: () => import('../../views/admin/accountancy/index.vue'),
  children: [
    {
      path: 'types',
      name: 'documentsType',
      component: () => import(/* webpackChunkName: "documentsType" */ '../../views/admin/accountancy/types/documentsType.vue'),
      meta: {
        permission: 'account.access',
        pageTitle: '',
        pageTitleI18n: 'accountancy.types.title'
      // rule: 'editor'
      }
    },
    {
      path: 'documents',
      component: () => import('../../views/admin/accountancy/documents/index.vue'),
      children: [
        {
          path: '',
          name: 'documents',
          component: () => import(/* webpackChunkName: "documents" */ '../../views/admin/accountancy/documents/list/documentsList.vue'),
          meta: {
            permission: 'document.access',
            pageTitle: '',
            pageTitleI18n: 'accountancy.documents.title'
          // rule: 'editor'
          }
        },
        {
          path: 'insert',
          name: 'insertDocument',
          component: () => import(/* webpackChunkName: "insertDocument" */ '../../views/admin/accountancy/documents/insert/insertDocument.vue'),
          meta: {
            permission: 'document.access',
            pageTitle: 'افزودن سند دریافت/پرداخت',
            pageTitleI18n: 'accountancy.documents.insert.dynamicTitle'
          // rule: 'editor'
          }
        },
        {
          path: 'doc-:id',
          name: 'document',
          component: () => import(/* webpackChunkName: "document" */ '../../views/admin/accountancy/documents/document/document.vue'),
          meta: {
            permission: 'document.access',
            pageTitle: '',
            pageTitleI18n: 'accountancy.documents.document.title'
          // rule: 'editor'
          }
        }
      ]
    },
    {
      path: 'opening-balance',
      name: 'openingBalance',
      component: () => import(/* webpackChunkName: "openingBalance" */ '../../views/admin/accountancy/openingBalance/index.vue'),
      meta: {
        permission: 'opening.access',
        pageTitle: '',
        pageTitleI18n: 'accountancy.openingBalance.title'
      // rule: 'editor'
      }
    },
    {
      path: 'taxes',
      component: () => import('../../views/admin/treasury/payment/index.vue'),
      children: [
        {
          path: '',
          name: 'taxesList',
          component: () => import(/* webpackChunkName: "taxesList" */ '../../views/admin/accountancy/taxes/list/taxList.vue'),
          meta: {
            pageTitle: 'مالیات',
            pageTitleI18n: 'accountancy.taxes.list.title',
            permission: 'tax.show'
          // rule: 'editor'
          }
        },
        {
          path: 'trash',
          name: 'taxesTrash',
          component: () => import(/* webpackChunkName: "taxesTrash" */ '../../views/admin/accountancy/taxes/trash/taxTrash.vue'),
          meta: {
            pageTitle: 'مالیات',
            pageTitleI18n: 'accountancy.taxes.trash.title',
            permission: 'tax.delete'
          // rule: 'editor'
          }
        },
        {
          path: 'insert',
          name: 'insertTax',
          component: () => import(/* webpackChunkName: "insertTax" */ '../../views/admin/accountancy/taxes/insert/insertTax.vue'),
          meta: {
            pageTitle: 'مالیات جدید',
            pageTitleI18n: 'accountancy.taxes.insert.title',
            permission: 'tax.create'
          // rule: 'editor'
          }
        },
        {
          path: 'doc-:id',
          name: 'taxDocument',
          component: () => import(/* webpackChunkName: "taxDocument" */ '../../views/admin/accountancy/taxes/document/taxDocument.vue'),
          meta: {
            pageTitle: 'سند مالیات',
            pageTitleI18n: 'accountancy.taxes.document.title',
            permission: 'tax.show'
          // rule: 'editor'
          }
        },
        {
          path: 'doc-:id/activities-log',
          name: 'taxActivitiesLog',
          component: () => import(/* webpackChunkName: "taxActivitiesLog" */ '../../views/admin/accountancy/taxes/activitiesLog/taxActivitiesLog.vue'),
          meta: {
            permission: 'activity_logs.show',
            pageTitle: 'لاگ های مالیات',
            pageTitleI18n: 'accountancy.taxes.logs.title'
          // rule: 'editor'
          }
        }
      ]
    }
  ]
}
