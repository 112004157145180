function showLoading (hideBackground) {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.classList.remove('in-active')
    appLoading.style.display = 'block'

    setTimeout(() => {
      appLoading.classList.add('active')
      if (hideBackground) {
        appLoading.classList.add('hide-background')
      }
    }, 10)
  }
}

function hideLoading () {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.classList.remove('active')
    appLoading.classList.remove('hide-background')
    appLoading.classList.add('in-active')

    setTimeout(() => {
      appLoading.style.display = 'none'
    }, 10)
  }
}

export const loading = {
  show: showLoading,
  hide: hideLoading
}
