// =============================================================================
// PROFILE PAGES LAYOUTS
// =============================================================================

export default {
  path: 'profile',
  component: () => import('../../views/admin/profile/index.vue'),
  children: [
    {
      path: '',
      component: () => import(/* webpackChunkName: "profile" */ '../../views/admin/profile/profile/userProfile.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'profile.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      },
      name: 'Profile'
    },
    {
      path: 'transactions',
      name: 'profileTransactions',
      component: () => import(/* webpackChunkName: "profileTransactions" */ '../../views/admin/profile/transactions/profileTransactions.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'profile.transactions.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'addresses',
      name: 'profileAddresses',
      component: () => import(/* webpackChunkName: "profileAddresses" */ '../../views/admin/profile/addresses/profileAddresses.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'profile.address.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'sessions',
      name: 'profileActiveSessions',
      component: () => import(/* webpackChunkName: "profileAddresses" */ '../../views/admin/profile/activeSessions/profileActiveSessions.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'profile.activeSessions.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'received-events',
      name: 'profileReceivedEvents',
      component: () => import(/* webpackChunkName: "profileReceivedEvents" */ '../../views/admin/profile/events/profileEvents.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'profile.events.receive.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'sent-events',
      name: 'profileSentEvents',
      component: () => import(/* webpackChunkName: "profileSentEvents" */ '../../views/admin/profile/events/profileEvents.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'profile.events.send.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'customize',
      name: 'profileCustomize',
      component: () => import(/* webpackChunkName: "profileCustomize" */ '../../views/admin/profile/customize/profileCustomize.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'profile.customize.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'edit',
      name: 'editProfile',
      component: () => import(/* webpackChunkName: "editProfile" */ '../../views/admin/profile/edit/editProfile.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'profile.edit.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    }
  ]
}
