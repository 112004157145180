// =============================================================================
// SETTING PAGES LAYOUTS
// =============================================================================

export default {
  path: 'settings',
  component: () => import('../../views/admin/settings/index.vue'),
  children: [
    {
      path: '',
      name: 'Settings',
      component: () => import(/* webpackChunkName: "Settings" */ '../../views/admin/settings/setting/settingMenu.vue'),
      meta: {
        pageTitle: 'تنظیمات',
        pageTitleI18n: 'setting.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'company-info',
      name: 'companyInfoSetting',
      component: () => import(/* webpackChunkName: "companyInfoSetting" */ '../../views/admin/settings/companyInfo/companyInfoSetting.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'setting.companyInfo.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'backup',
      name: 'backup',
      component: () => import(/* webpackChunkName: "backup" */ '../../views/admin/settings/backup/backup.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'backup.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'roles',
      component: () => import(/* webpackChunkName: "access" */ '../../views/admin/settings/access/index.vue'),
      children: [
        {
          path: '',
          name: 'accessGroupsList',
          component: () => import(/* webpackChunkName: "accessGroupsList" */ '../../views/admin/settings/access/list/accessGroupsList.vue'),
          meta: {
            permission: 'role.show',
            pageTitle: '',
            pageTitleI18n: 'setting.accessGroup.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        }
      ]
    },
    {
      path: 'purchase',
      name: 'purchaseSetting',
      component: () => import(/* webpackChunkName: "purchaseSetting" */ '../../views/admin/settings/purchase/PurchaseSettings.vue'),
      meta: {
        pageTitle: 'تنظیمات خرید',
        pageTitleI18n: 'setting.purchase.title',
        permission: 'setting.show',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'sale',
      name: 'saleSetting',
      component: () => import(/* webpackChunkName: "saleSetting" */ '../../views/admin/settings/sale/SaleSettings.vue'),
      meta: {
        pageTitle: 'تنظیمات فروش',
        pageTitleI18n: 'setting.sale.title',
        permission: 'setting.show',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'languages',
      name: 'languagesSetting',
      component: () => import(/* webpackChunkName: "languagesSetting" */ '../../views/admin/settings/locale/LocaleSetting.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'setting.Locale.title',
        permission: 'setting.show',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'users',
      name: 'usersSetting',
      component: () => import(/* webpackChunkName: "usersSetting" */ '../../views/admin/settings/users/usersSetting.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'setting.users.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'security',
      name: 'securitySetting',
      component: () => import(/* webpackChunkName: "securitySetting" */ '../../views/admin/settings/security/securitySetting.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'setting.security.title',
        permission: 'setting.show',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'customizing',
      name: 'customizeSetting',
      component: () => import(/* webpackChunkName: "customizeSetting" */ '../../views/admin/settings/customize/customizeSetting.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'setting.customize.title',
        permission: 'setting.show',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'financial',
      name: 'financialSetting',
      component: () => import(/* webpackChunkName: "financialSetting" */ '../../views/admin/settings/financial/FinancialSetting.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'setting.financial.title',
        permission: 'setting.show',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'product',
      name: 'productSetting',
      component: () => import(/* webpackChunkName: "productSetting" */ '../../views/admin/settings/product/ProductSetting.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'setting.product.title',
        permission: 'setting.show',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'tax',
      name: 'taxSetting',
      component: () => import(/* webpackChunkName: "taxSetting" */ '../../views/admin/settings/tax/TaxSetting.vue'),
      meta: {
        pageTitle: '',
        pageTitleI18n: 'setting.tax.title',
        permission: 'setting.show',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'store',
      name: 'storeSetting',
      component: () => import(/* webpackChunkName: "storeSetting" */ '../../views/admin/settings/store/StoreSettings.vue'),
      meta: {
        pageTitle: 'تنظیمات انبار',
        pageTitleI18n: 'setting.warehouse.title',
        permission: 'setting.show',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'financial-year',
      name: 'financialYearSetting',
      component: () => import(/* webpackChunkName: "financialYearSetting" */ '../../views/admin/settings/financialYear/list/financialYearList.vue'),
      meta: {
        permission: 'financial_year.show',
        pageTitle: 'تنظیمات سال مالی',
        pageTitleI18n: 'setting.financialYear.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'shipping',
      name: 'shippingSetting',
      component: () => import(/* webpackChunkName: "shippingSetting" */ '../../views/admin/settings/shipping/ShippingSetting.vue'),
      meta: {
        permission: 'setting.show',
        pageTitle: 'تنظیمات حمل و نقل',
        pageTitleI18n: 'setting.shipping.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'print',
      name: 'printSetting',
      component: () => import(/* webpackChunkName: "shippingSetting" */ '../../views/admin/settings/print/PrintSetting.vue'),
      meta: {
        permission: 'setting.show',
        pageTitle: 'تنظیمات پرینت',
        pageTitleI18n: 'setting.print.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'message',
      name: 'messageSetting',
      component: () => import(/* webpackChunkName: "messageSetting" */ '../../views/admin/settings/message/messageSetting.vue'),
      meta: {
        permission: 'setting.show',
        pageTitle: 'تنظیمات پیامک',
        pageTitleI18n: 'setting.message.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'report',
      name: 'reportSetting',
      component: () => import(/* webpackChunkName: "reportSetting" */ '../../views/admin/settings/report/reportSettings.vue'),
      meta: {
        permission: 'setting.show',
        pageTitle: 'تنظیمات گزارش',
        pageTitleI18n: 'setting.report.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'advanced',
      name: 'advancedSetting',
      component: () => import(/* webpackChunkName: "advancedSetting" */ '../../views/admin/settings/advanced/advancedSetting.vue'),
      meta: {
        pageTitle: 'تنظیمات پیشرفته',
        pageTitleI18n: '',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    },
    {
      path: 'reset',
      name: 'resetSetting',
      component: () => import(/* webpackChunkName: "resetSetting" */ '../../views/admin/settings/reset/ResetSetting.vue'),
      meta: {
        permission: 'setting.reset_application',
        pageTitle: 'تنظیمات ریست',
        pageTitleI18n: 'setting.reset.title',
        layoutStatus: 'profile'
      // rule: 'editor'
      }
    }
  ]
}
