export default {
  path: 'characters',
  component: () => import('../../views/admin/characters/index.vue'),
  children: [
    {
      path: '',
      name: 'characters',
      component: () => import('../../views/admin/characters/list/charactersList.vue'),
      meta: {
        permission: 'user_group.show',
        pageTitle: 'گروه مشتریان',
        pageTitleI18n: 'characters.list.title'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'charactersTrash',
      component: () => import('../../views/admin/characters/trash/charactersTrash.vue'),
      meta: {
        permission: 'user_group.delete',
        pageTitle: 'سطل زباله گروه مشتریان',
        pageTitleI18n: 'characters.trash.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      component: () => import('../../views/admin/characters/profile/index.vue'),
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "character" */ '../../views/admin/characters/profile/characterProfile.vue'),
          name: 'character',
          meta: {
            permission: 'user_group.show',
            pageTitle: 'گروه شخص',
            pageTitleI18n: 'characters.profile.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'edit',
          name: 'editCharacter',
          component: () => import(/* webpackChunkName: "editCharacter" */ '../../views/admin/characters/edit/editCharacter.vue'),
          meta: {
            permission: 'user_group.update',
            pageTitle: 'ویرایش گروه شخص',
            pageTitleI18n: 'characters.edit.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'users',
          name: 'characterUsersList',
          component: () => import(/* webpackChunkName: "characterUsersList" */ '../../views/admin/characters/users/list/characterUserList.vue'),
          meta: {
            permission: 'user_group.assign_to_user',
            pageTitle: ',افزودن شخص به گروه شخص',
            pageTitleI18n: 'characters.user.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        }
      ]
    }
  ]
}
