// =============================================================================
// SERVICE PAGES LAYOUTS
// =============================================================================

export default {
  path: 'services',
  component: () => import('../../views/admin/services/index'),
  children: [
    {
      path: '',
      component: () => import('../../views/admin/services/servicesList/index'),
      children: [
        {
          path: '',
          name: 'ServicesList',
          component: () => import(/* webpackChunkName: "ServicesList" */ '../../views/admin/services/servicesList/list/servicesList.vue'),
          meta: {
            pageTitle: 'لیست خدمت',
            pageTitleI18n: 'services.list.title'
          // rule: 'editor'
          }
        },
        {
          path: 'trash',
          name: 'ServicesTrash',
          component: () => import(/* webpackChunkName: "ServicesTrash" */ '../../views/admin/services/servicesList/trash/servicesTrash.vue'),
          meta: {
            pageTitle: 'سطل آشغال خدمت',
            pageTitleI18n: 'services.trash.title'
          // rule: 'editor'
          }
        },
        {
          path: 'service-:id',
          component: () => import('../../views/admin/services/servicesList/service/index.vue'),
          children: [
            {
              path: '',
              name: 'Service',
              component: () => import(/* webpackChunkName: "Service" */ '../../views/admin/services/servicesList/service/menu/serviceMenu.vue'),
              meta: {
                pageTitle: 'خدمت',
                pageTitleI18n: 'services.service.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'activities-log',
              name: 'serviceActivitiesLog',
              component: () => import(/* webpackChunkName: "serviceActivitiesLog" */ '../../views/admin/services/servicesList/service/activitiesLog/serviceActivitiesLog.vue'),
              meta: {
                permission: 'activity_logs.show',
                pageTitle: 'لاگ های خدمت',
                pageTitleI18n: 'services.service.logs.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            },
            {
              path: 'edit',
              name: 'EditService',
              component: () => import(/* webpackChunkName: "EditProduct" */ '../../views/admin/services/servicesList/edit/editService.vue'),
              meta: {
                pageTitle: 'ویرایش خدمت',
                pageTitleI18n: 'services.edit.title',
                layoutStatus: 'profile'
              // rule: 'editor'
              }
            }
          ]
        },
        {
          path: 'insert',
          name: 'InsertService',
          component: () => import(/* webpackChunkName: "InsertService" */ '../../views/admin/services/servicesList/insert/insertService.vue'),
          meta: {
            pageTitle: 'افزودن خدمت',
            pageTitleI18n: 'services.insert.title'
          // rule: 'editor'
          }
        }
      ]
    }
  ]
}
