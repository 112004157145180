<template>
  <div class="insert-attribute-box">
    <vs-row>
      <custom-validate-input class="w-full"
                             v-model="newAttributes.name"
                             :in-valid="$v.newAttributes.name.$invalid"
                             :autocomplete="false"
                             :name="Math.random()"
                             :label="$t('attributes.labels.name')"
                             @input="handleNameChange"
                             @pressEnter="goToNextField(0)"/>
      <custom-validate-input class="w-full"
                             v-model="newAttributes.slug"
                             :in-valid="$v.newAttributes.slug.$invalid"
                             :autocomplete="false"
                             :name="Math.random()"
                             :label="$t('attributes.labels.slug')"
                             @pressEnter="goToNextField(0)"/>

      <u-checkbox :label="$t('attributes.labels.archive')" v-model="newAttributes.archive" />
    </vs-row>

    <vs-row v-if="$u.checkPermissions('attribute_value.show') && false">
      <div class="values-box w-full">
        <div class="values-header">
          <div class="header">{{ $t('attributes.labels.values') }}</div>

          <div class="mx-1"
               v-if="$u.checkPermissions('attribute_value.create')"
               @click="selectedValue = {}, attributeValuePromptStatus = true">
            <custom-icon icon="PLUS"
                         color="success"
                         width="20px"
                         height="20px"
                         :cursor-pointer="true"/>
          </div>
        </div>
        <ul class="values">
          <li v-for="(value, value_index) in newAttributes.values"
              :key="value_index"
              @click="handleClickValues(value)">
            {{ value.value }}
          </li>
        </ul>
      </div>

      <!-- insert / edit value prompt -->
      <vs-prompt
        class="p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="attributeValuePromptStatus">

        <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
          <vs-row>
            <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
              <div @click="$u.click('saveBTN')">
                <custom-icon icon="SAVE" color="success"/>
              </div>
            </vs-col>

            <vs-spacer/>

            <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
              {{ selectedValue.hasOwnProperty('id') ? $t('attributes.labels.editValue') : $t('attributes.labels.insertValue') }}
            </vs-col>

            <vs-spacer/>

            <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
              <div @click="attributeValuePromptStatus = false">
                <custom-icon icon="TIMES-CIRCLE" color="danger"/>
              </div>
            </vs-col>
          </vs-row>
        </div>

        <div class="prompt-content py-2 px-3">
          <template>
            <keep-alive>
              <update-attribute-value v-if="selectedValue.hasOwnProperty('id')"
                                      :attribute-id="attribute.id"
                                      :value="selectedValue"
                                      :shops="newAttributes.shops"
                                      @updated="updateAttributeValue($event)"/>
              <insert-attribute-value v-else
                                      :attribute-id="attribute.id"
                                      :shops="newAttributes.shops"
                                      @inserted="insertAttributeValue($event)"/>
            </keep-alive>
          </template>
        </div>

      </vs-prompt>
    </vs-row>

    <vs-row>
      <div class="categories-box w-full">
        <div class="w-full">{{ $t('attributes.labels.categories') }}</div>

        <template v-for="(category, category_index) in categories">
          <custom-tree :node="category.node"
                       :children="category.children"
                       :key="category_index"
                       :config="treeConfig"
                       :actions="category.actions"
                       v-model="newAttributes.categories"/>
        </template>
      </div>
    </vs-row>

    <vs-row>
      <div class="categories-box w-full">
        <div class="w-full">{{ $t('attributes.labels.shops') }}</div>

        <custom-tree v-for="(shop, shop_index) in shops"
                     :key="shop_index"
                     :node="shop.node"
                     :config="treeConfig"
                     :actions="shop.actions"
                     v-model="newAttributes.shops"/>
      </div>
    </vs-row>

    <vs-button id="saveAttributesBTN" class="useral-action-button" @click="sendData()"/>
  </div>
</template>

<script>
import {deleteAttributeValue} from '@/http/requests/products'
import {getCategories} from '@/http/requests/categories'
import {
  insertAttribute, insertAttributeValue,
  updateAttributeValue
} from '@/http/requests/attributes'
import CustomDialog from '@/components/customDialog/customDialog'
import {getShops} from '@/http/requests/plugins/wooreceiver'
import CustomIcon from '@/components/customIcon/customIcon'
import InsertAttributeValue from '@/views/admin/products/Attributes/values/insertAttributeValue'
import UpdateAttributeValue from '@/views/admin/products/Attributes/values/updateAttributeValue'
import CustomTree from '@/components/Tree/CustomTree.vue'
import CustomValidateInput from '@/components/customInput/customValidateInput.vue'
import UCheckbox from '@/components/customCheckBox/UCheckbox.vue'
import {maxLength, required} from 'vuelidate/lib/validators'

export default {
  name: 'insertAttribute',
  components: {
    UCheckbox,
    CustomValidateInput,
    CustomTree,
    UpdateAttributeValue,
    InsertAttributeValue,
    CustomIcon,
    CustomDialog
  },
  model: {
    prop: 'attribute',
    event: 'attribute:inserted'
  },
  props: {
    attribute: {
      type: Object,
      default: () => { return {} }
    },
    categoryId: {
      type: Number,
      default: 0,
      validator (value) {
        return parseInt(value || 0) >= 0
      }
    }
  },
  data () {
    return {
      attributeValuePromptStatus: false,
      selectedValue: {},
      insertAttributeValueTimer: 0,
      addValueTimer: 0,
      copyAttributes: {},
      newAttributes: {
        name: {
          value: '',
          isValid: true
        },
        slug: {
          value: '',
          isValid: true
        },
        archive: true,
        values: [],
        categories: [],
        shops: []
      },
      categoryTreeOptions: {
        type: 'insertCategory'
      },
      shopTreeOptions: {
        type: ''
      },
      shops: [],
      treeConfig: {
        actions: [],
        collapse: true,
        selectable: true,
        parentSelectable: false
      },
      noCategory: {
        node: {
          id: 4,
          label: this.$t('attributes.labels.withoutCategory'),
          show: true
        },
        children: [],
        actions: {
          insert: false,
          update: false,
          delete: false
        }
      },
      categories: []
    }
  },
  validations: {
    newAttributes: {
      name: {
        value: {
          required,
          maxLength: maxLength(30)
        }
      },
      slug: {
        value: {
          required,
          maxLength: maxLength(30)
        }
      }
    }
  },
  created () {
    this.getCategoriesList()
    this.getShops()
    this.generateTreeActions()
  },
  methods: {
    getShops () {
      getShops(1, {status: '3'}).then(response => {
        const shops = response.data.data

        shops.forEach((shop) => {
          this.shops.push({
            node: {
              id: shop.id,
              label: shop.name
            },
            children: [],
            actions: {
              insert: false,
              update: false,
              delete: false
            }
          })
        })

        if (shops.length === 1) {
          this.newAttributes.shops = [shops[0].id]
        }
      })
    },
    generateTreeActions () {
      /*if (this.$u.checkPermissions('role.create')) {
        this.treeConfig.actions.push({
          key: 'insert',
          icon: 'PLUS',
          color: 'success',
          event: 'insert'
        })
      }
      if (this.$u.checkPermissions('role.update')) {
        this.treeConfig.actions.push({
          key: 'update',
          icon: 'EDIT',
          color: 'warning',
          event: 'update'
        })
      }
      if (this.$u.checkPermissions('role.delete')) {
        this.treeConfig.actions.push({
          key: 'delete',
          icon: 'TRASH',
          color: 'danger',
          event: 'delete'
        })
      }*/
    },
    handleNameChange () {
      this.newAttributes.slug.value = this.newAttributes.name.value.toLowerCase().replaceAll(' ', '-')
    },
    getCategoriesList () {
      this.categories = []
      getCategories().then(response => {
        const categories = response.data.data
        categories.forEach(category => {
          this.categories = [...this.categories, ...this.getCategories(category)]
        })
      }).catch(() => {
      })
    },
    getCategories (category) {
      const node = {
        id: category.id,
        label: category.name,
        show: false
      }
      let categories = [
        {
          node,
          actions: []
        }
      ]
      this.newAttributes.categories.push(node)

      category.children.forEach((child) => {
        categories = [...categories, ...this.getCategories(child)]
      })

      return categories
    },
    activeSelectedCategories () {
      this.newAttributes.categories.push(this.noCategory.node)

      let selectedCategories = []

      if (this.categoryId > 0) selectedCategories.push(this.categoryId)

      selectedCategories = [
        ...selectedCategories,
        ...this.attribute.categories.map(elm => {
          return elm.id
        })
      ]

      if (selectedCategories.length > 0) {
        this.newAttributes.categories.forEach((category) => {
          if (selectedCategories.indexOf(category.id) !== -1) category.show = true
        })
      }

      if (selectedCategories.length !== this.newAttributes.categories.length) {
        const newAttributeCategoriesId = this.newAttributes.categories.map(elm => {
          return elm.id
        })
        selectedCategories.forEach((category) => {
          if (newAttributeCategoriesId.indexOf(category) === -1) {
            const cat = {
              'id': category,
              'name': '',
              'show': true
            }
            this.categories.push(cat)
            this.newAttributes.categories.push(cat)
          }
        })
      }
    },
    goToNextField (index) {
      document.getElementsByTagName('input')[index + 1].focus()
    },
    handleTypeAttributeValue (index) {
      if (this.newAttributes.values[index].value && this.newAttributes.values[index].value.trim().length > 0) {
        this.addValue()
        this.setInputWidth(index)
        // this.handleInsertAttributeValue(index)
      } else {
        if (this.hasEmptyInput() <= 1) {
          this.moveFocus(index)
        }

        this.handleRemoveAttributeValue(index)
      }
    },
    handleInsertAttributeValue (index) {
      if (this.attribute && !this.newAttributes.values[index].id) {
        clearTimeout(this.insertAttributeValueTimer)
        this.insertAttributeValueTimer = setTimeout(() => {
          insertAttributeValue({
            id: this.attribute.id,
            value: this.newAttributes.values[index].value
          }).then(() => {

          })
        }, 400)
      }
    },
    handleRemoveAttributeValue (index) {
      if (this.newAttributes.values[index].id) {
        deleteAttributeValue(this.newAttributes.values[index].id).then(() => {
          /*this.$vs.notify({
            title: 'Alert',
            text: 'OK',
            time: this.$u.getReadingTime('OK'),
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather'
          })*/
        })
      }

      if (this.hasEmptyInput() > 1) {
        this.newAttributes.values.splice(index, 1)
      }
    },
    setInputWidth (index) {
      let width = this.newAttributes.values[index].length * 8 > 100 ? this.newAttributes.values[index].length * 8 : 100
      width = width > 200 ? 200 : width
      const element = document.getElementsByTagName('input')[index + 1]
      element.setAttribute('style', `width: ${width}px !important`)
    },
    hasEmptyInput () {
      let counter = 0
      this.newAttributes.values.forEach((value) => {
        if (value.value.trim().length === 0) {
          counter++
        }
      })
      return counter
    },
    moveFocus (index) {
      if (index > 0) {
        document.getElementsByTagName('input')[index].focus()
      }
    },
    addValue () {
      if (this.newAttributes.values.map((elm) => {
        return elm.value
      }).indexOf(' ') === -1) {
        clearTimeout(this.addValueTimer)
        this.addValueTimer = setTimeout(() => {
          this.newAttributes.values.push({value: ' '})
        }, 500)
      } else {
        // document.getElementsByTagName('input')[this.newAttributes.values.indexOf(' ') + 1].focus()
      }
    },
    sendData () {
      if (!this.$v.newAttributes.name.value.maxLength) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.name', {num: 30}),
          time: this.$u.getReadingTime(this.$t('attributes.validators.name', {num: 30})),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }
      if (!this.$v.newAttributes.slug.value.maxLength) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.slug', {num: 30}),
          time: this.$u.getReadingTime(this.$t('attributes.validators.slug', {num: 30})),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }
      if (!this.$v.newAttributes.name.value.required) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.nameRequired'),
          time: this.$u.getReadingTime(this.$t('attributes.validators.nameRequired')),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }
      if (!this.$v.newAttributes.slug.value.required) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.slugRequired'),
          time: this.$u.getReadingTime(this.$t('attributes.validators.slugRequired')),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      /*const values = []
        this.newAttributes.values.forEach((value) => {
          if (value.value.trim()) {
            values.push({value: value.value.trim()})
          }
        })*/

      const attribute = {
        name: this.newAttributes.name.value,
        slug: this.newAttributes.slug.value,
        has_archives: this.newAttributes.archive,
        shops: this.newAttributes.shops
      }
      attribute.categories = this.newAttributes.categories.filter((category) => {
        return category.show === true
      }).map((category) => {
        return category.id
      })

      insertAttribute(attribute).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('attributes.insert.notifications.insert.success'),
          color: 'success',
          time: this.$u.getReadingTime(this.$t('attributes.insert.notifications.insert.success')),
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeAttribute')
      }).catch((error) => {

        const error_mapper = {
          'slug': this.$t('attributes.notifications.parseError.slug')
        }
        switch (error.response.status) {
        case 422:
          Object.keys(error.response.data.errors).forEach((error_key) => {
            const err = error_key.toString().split('.')
            if (error_mapper[err[err.length - 1]]) {
              this.$vs.notify({
                title: this.$t('alert.message.title'),
                text: error_mapper[err[err.length - 1]],
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: this.$u.getReadingTime(error_mapper[err[err.length - 1]])
              })
              error_mapper[err[err.length - 1]] = null
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('attributes.insert.notifications.insert.error'),
                color: 'danger',
                time: this.$u.getReadingTime(this.$t('attributes.insert.notifications.insert.error')),
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
          break

        default:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('attributes.insert.notifications.insert.error'),
            color: 'danger',
            time: this.$u.getReadingTime(this.$t('attributes.insert.notifications.insert.error')),
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          break
        }
      })
    },
    insertAttributeValue (value) {
      const data = {
        value: value.value.value,
        shops: this.newAttributes.shops
      }
      insertAttributeValue(this.attribute.id, data).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('attributes.insert.notifications.insertValue.success'),
          time: this.$u.getReadingTime(this.$t('attributes.insert.notifications.insertValue.success')),
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeAttributeValues')
      }).catch(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.insert.notifications.insertValue.error'),
          time: this.$u.getReadingTime(this.$t('attributes.insert.notifications.insertValue.error')),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    },
    updateAttributeValue (value) {
      const data = {
        value: value.value.value
      }
      updateAttributeValue(value.id, data).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('attributes.edit.notifications.editValue.success'),
          time: this.$u.getReadingTime(this.$t('attributes.edit.notifications.editValue.success')),
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeAttributeValues')
      }).catch(() => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.edit.notifications.editValue.error'),
          time: this.$u.getReadingTime(this.$t('attributes.edit.notifications.editValue.error')),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    },
    handleClickValues (value) {
      this.selectedValue = value
      if (this.$u.checkPermissions('attribute_value.update')) {
        this.attributeValuePromptStatus = true
      }
    }
  },
  watch: {
    '$store.state.helper.attributeValuesChanged': {
      handler (val) {
        if (val) {
          this.attributeValuePromptStatus = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.insert-attribute-box {

  input.attribute-name {
    padding: .7rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #cecece;
  }

  .values-box,
  .categories-box {
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px;
  }

  .values-box {
    .values-header {
      display: flex;

      .header {
        flex-grow: 1;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        text-align: center;
        min-width: 75px;
        margin: 2px;
        padding: 5px;
        background: #ededed;
        border-radius: .5rem;
        cursor: pointer;
      }
    }
  }
}
</style>
