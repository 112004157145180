import store from '@/store/store'
import { loading } from '@/prototypes/u/loading'

export function afterEach (app) {
  store.dispatch('removePageChanges')
  if (!app.meta.noHideLoading && app.name !== undefined) {
    // Remove initial loading
    loading.hide()
  }
  if (localStorage.newContent) {
    window.history.replaceState({
      // ...history.state,
      newContent: true
    }, '')
    localStorage.removeItem('newContent')
  }
}
