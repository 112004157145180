// =============================================================================
// PLUGINS PAGES LAYOUTS
// =============================================================================

export default {
  path: 'wooreceiver',
  component: () => import('../../views/admin/plugins/wooreciever/index.vue'),
  children: [
    {
      path: '',
      name: 'wooreceiverList',
      component: () => import(/* webpackChunkName: "wooreceiverList" */ '../../views/admin/plugins/wooreciever/list/wooreceiver.vue'),
      meta: {
        permission: 'shop.show',
        pageTitle: '',
        pageTitleI18n: 'plugins.wooreceiver.title'
      // rule: 'editor'
      }
    },
    {

      path: ':id',
      component: () => import('../../views/admin/plugins/wooreciever/profile/index.vue'),
      children: [
        {
          path: '',
          name: 'wooreceiverProfile',
          component: () => import(/* webpackChunkName: "wooreceiverProfile" */ '../../views/admin/plugins/wooreciever/profile/wooreceiverProfile.vue'),
          meta: {
            permission: 'shop.show',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.profile.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'settings',
          name: 'wooreceiverSettings',
          component: () => import(/* webpackChunkName: "wooreceiverSettings" */ '../../views/admin/plugins/wooreciever/settings/wooreceiverSettings.vue'),
          meta: {
            permission: 'shop.update_setting',
            pageTitle: 'تنظیمات اتصال ساز',
            pageTitleI18n: 'plugins.wooreceiver.setting.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'attributes',
          name: 'wooreceiverAttributes',
          component: () => import(/* webpackChunkName: "wooreceiverAttributes" */ '../../views/admin/plugins/wooreciever/attributes/wooreceiverAttributesList.vue'),
          meta: {
            permission: 'woo_controller_attribute.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.attributes.list.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'users',
          name: 'wooreceiverUsers',
          component: () => import(/* webpackChunkName: "wooreceiverUsers" */ '../../views/admin/plugins/wooreciever/users/wooreceiverUsers.vue'),
          meta: {
            permission: 'woo_controller_user.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.users.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'products',
          name: 'wooreceiverProducts',
          component: () => import(/* webpackChunkName: "wooreceiverProducts" */ '../../views/admin/plugins/wooreciever/products/wooreceiverProducts.vue'),
          meta: {
            permission: 'woo_controller_product.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.products.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'products-inventory',
          name: 'wooreceiverProductsInventory',
          component: () => import(/* webpackChunkName: "wooreceiverProductsInventory" */ '../../views/admin/plugins/wooreciever/productsInventory/wooreceiverProductsInventory.vue'),
          meta: {
            permission: 'woo_controller_product_inventory.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.inventory.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'products-prices',
          name: 'wooreceiverProductsPrices',
          component: () => import(/* webpackChunkName: "wooreceiverProductsPrices" */ '../../views/admin/plugins/wooreciever/productsPrices/wooreceiverProductsPrices.vue'),
          meta: {
            permission: 'woo_controller_product_price.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.prices.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'payment-gateways',
          name: 'wooreceiverPaymentGateways',
          component: () => import(/* webpackChunkName: "wooreceiverPaymentGateways" */ '../../views/admin/plugins/wooreciever/paymentGateways/paymentGateways.vue'),
          meta: {
            permission: 'woo_controller_payment_gateways.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.paymentGateways.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'orders',
          name: 'wooreceiverOrders',
          component: () => import(/* webpackChunkName: "wooreceiverOrders" */ '../../views/admin/plugins/wooreciever/orders/wooreceiverOrders.vue'),
          meta: {
            permission: 'woo_controller_invoice.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.orders.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'categories',
          name: 'wooreceiverCategories',
          component: () => import(/* webpackChunkName: "wooreceiverCategories" */ '../../views/admin/plugins/wooreciever/categories/wooreceiverCategories.vue'),
          meta: {
            permission: 'woocontroller.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.categories.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'categories-mapper',
          name: 'wooreceiverCategoriesMapper',
          component: () => import(/* webpackChunkName: "wooreceiverCategoriesMapper" */ '../../views/admin/plugins/wooreciever/categories/wooreceiverCategoriesMapper.vue'),
          meta: {
            permission: 'woocontroller.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.categories.categoryMapper.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        }
      ]
    },
    {
      path: ':id/products/:productId',
      component: () => import('../../views/admin/plugins/wooreciever/products/index.vue'),
      children: [
        {
          path: '',
          name: 'wooreceiverProductProfile',
          component: () => import(/* webpackChunkName: "wooreceiverProduct" */ '../../views/admin/plugins/wooreciever/products/wooreceiverProductProfile.vue'),
          meta: {
            permission: 'shop.show',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.products.profile.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'edit',
          name: 'wooreceiverEditProduct',
          component: () => import(/* webpackChunkName: "wooreceiverEditProduct" */ '../../views/admin/plugins/wooreciever/products/wooreceiverEditProduct.vue'),
          meta: {
            permission: 'woo_controller_product.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.products.edit.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'attributes',
          name: 'wooreceiverAttributesProduct',
          component: () => import(/* webpackChunkName: "wooreceiverAttributesProduct" */ '../../views/admin/plugins/wooreciever/products/wooreceiverProductAttributes.vue'),
          meta: {
            permission: 'woo_controller_product.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.products.attributes.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'variants',
          name: 'wooreceiverVariantsProduct',
          component: () => import(/* webpackChunkName: "wooreceiverVariantsProduct" */ '../../views/admin/plugins/wooreciever/products/wooreceiverProductVariants.vue'),
          meta: {
            permission: 'woo_controller_product.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.products.variant.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        },
        {
          path: 'logs',
          name: 'wooreceiverProductLogs',
          component: () => import(/* webpackChunkName: "wooreceiverProductLogs" */ '../../views/admin/plugins/wooreciever/products/wooreceiverProductLogs.vue'),
          meta: {
            permission: 'woo_controller_product.access',
            pageTitle: '',
            pageTitleI18n: 'plugins.wooreceiver.products.logs.title',
            layoutStatus: 'profile'
          // rule: 'editor'
          }
        }
      ]
    }
  ]
}
