import Vue from 'vue'
import store from '@/store/store'
import {getProfile} from '@/http/requests/profile'
import { loading } from '@/prototypes/u/loading'
import { checkPermissions, getAssetsUrl } from '@/prototypes/u/other'
import { cookie } from '@/prototypes/u/cookie'
import {getCompanyInfo} from '../http/requests/auth'
import {setLocale} from '@/prototypes/u/locale'

function validateUser (routeInfo) {
  return getProfile(routeInfo)
}


const $t = sign => Vue.prototype.i18n.t(sign)

function getCompanyInformation (routeName = {}) {
  getCompanyInfo(routeName).then((response) => {
    const company = response.data
    const clubInfo = {
      club_name: company.company_name,
      club_logo: company.company_avatar ? getAssetsUrl(company.company_avatar, '', routeName) : '',
      club_currency: company.club_currency || '',
      club_currency_code: company.club_currency_code || 'NUN',
      club_language: company.club_language || 'fa',
      club_locale: company.club_locale || 'IRI',
      club_default_country_code: company.club_default_country_code || ''
    }
    if (!routeName.params.hasOwnProperty('tenant')) {
      clubInfo.club_core_name = company.company_name
      clubInfo.club_core_logo = company.company_avatar ? getAssetsUrl(company.company_avatar, '', routeName) : ''
    }
    store.dispatch('setting/setClubInfo', clubInfo)
    setLocale(company.club_language)
  })
}

function checkPageChange (next) {
  if (store.state.pageHaveChanges) {
    const answer =
      window.confirm($t('alert.unsavedChanges'))

    if (answer) {
      loading.show()
      store.dispatch('removePageChanges')
      next()
    } else {
      loading.hide()
      next(false)
    }
  } else {
    loading.show()
    next()
  }
}

export async function beforeEach (to, from, next) {
  /* in this place check user was logged in or not */

  try {
    store.dispatch('helper/changePrevRoute', from)
    store.dispatch('updateContentNavbarClass', '')
    store.dispatch('setPageTitle', '')
    const originDomain = window.location.href

    const originAccessToken = cookie.get(`accessToken.${originDomain}`)
    if (originAccessToken) {
      let domain = window.location.hostname.split('.').reverse()
      if (domain.length > 2) domain = domain.splice(0, 2)
      domain = domain.reverse().join('.')
      localStorage.setItem('accessToken', originAccessToken)
      console.log(originDomain, originAccessToken, domain)
      cookie.delete(`accessToken.${originDomain}`, domain)
    }

    const accessToken = localStorage.getItem('accessToken') || cookie.get('accessToken')

    if (accessToken) {
      await store.dispatch('auth/setAccessToken').then(() => {
        if (from.name === null || (to.params.hasOwnProperty('tenant') && to.params.tenant !== from.params.tenant)) {
          getCompanyInformation(to)
        }
        const loadingEl = window.document.getElementById('loadingImg')
        if (loadingEl) {
          loadingEl.src = store.state.setting.clubInfo[to.params.hasOwnProperty('tenant') ? 'club_logo' : 'club_core_logo'] || '/logo.png'
        }
        if (to.name === 'page-login') {
          next({name: 'selectCompany'})
        }
        if (from.name === null || from.name === 'page-login') {
          validateUser(to).then((validate) => {
            if (validate.status === 200) {
              const userData = validate.data.data
              userData.route = to
              store.dispatch('auth/updateUserInfo', userData)

              const userInfo = store.state.auth.user
              if (userInfo.role) {
                const allowedActiveSessions = userInfo.role && userInfo.role.allowed_active_sessions ? userInfo.role.allowed_active_sessions : 3
                if (to.name !== 'selectCompany' && userInfo.active_sessions > allowedActiveSessions && to.name !== 'Profile' && to.name !== 'profileActiveSessions') {
                  next({name: store.getters.isMobile ? 'profileActiveSessions' : 'Profile', params: {tenant: to.params.tenant}})
                  loading.hide()
                }
              }

              const browserSetting = validate.data.data && validate.data.data.browser_setting ? validate.data.data.browser_setting : JSON.stringify({})
              const tableSettings = JSON.parse(browserSetting)
              // delete tableSettings.tables.payload
              if (tableSettings) {
                store.dispatch('table/setUserBrowserSettings', tableSettings)
              }

              if (to.meta.hasOwnProperty('permission')) {
                if (checkPermissions(to.meta.permission)) {
                  checkPageChange(next)
                  // next()
                } else {
                  next({name: 'page-error-404'})
                }
              } else {
                checkPageChange(next)
              }
            } else {
              throw 'auth-error'
            }
          })
        } else {
          const userInfo = store.state.auth.user
          if (userInfo.role) {
            if (userInfo.active_sessions > userInfo.role.allowed_active_sessions && to.name !== 'Profile' && to.name !== 'profileActiveSessions') {
              next({name: store.getters.isMobile ? 'profileActiveSessions' : 'Profile', params: {tenant: to.params.tenant}})
              loading.hide()
            }
          }

          if (to.meta.hasOwnProperty('permission')) {
            if (checkPermissions(to.meta.permission)) {
              checkPageChange(next)
            } else {
              next({name: 'page-error-404'})
            }
          } else {
            checkPageChange(next)
          }
        }
      })
    } else if (!to.matched.some(record => record.name === 'page-login')) {
      throw 'auth-error'
    } else {
      if (from.name === null || (to.params.hasOwnProperty('tenant') && to.params.tenant !== from.params.tenant)) {
        getCompanyInformation()
      }
      next()
    }
  } catch (e) {
    console.log(e)
    if (!to.matched.some(record => record.name === 'page-login')) {
      next({
        name: 'page-login'
        /* query: {redirectFrom: to.fullPath}*/
      })
      // next(false)
    } else {
      next()
    }
  }
}
