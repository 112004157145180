export default {
  methods: {
    getLogSubject (key, data = {}) {
      switch (key) {
      case 'delete_old_sale_invoices':
      case 'delete_old_purchase_invoices':
      case 'delete_old_return_sale_invoices':
      case 'delete_old_return_purchase_invoices':
        return this.$t(`logs.subject.${Object.values(data).length > 0 ? `${key}_dynamic` : key}`, data)

      case 'woo_controller_invoice_technical_problem':
      case 'woo_controller_invoice_transferred':
      case 'woo_controller_invoice_updated':
      case 'create_insert_order_webhook_succeed':
      case 'create_insert_order_webhook_failed':
      case 'create_update_order_webhook_succeed':
      case 'create_update_order_webhook_failed':
      case 'update_woo_controller_order_status_in_shop_succeed':
      case 'update_woo_controller_order_status_in_shop_failed':
        return this.$t(`logs.subject.invoices.${Object.values(data).length > 0 ? `${key}_dynamic` : key}`, data)

      case 'woo_controller_user_technical_problem':
      case 'woo_controller_user_transferred':
      case 'delete_woo_controller_user_from_shop_succeed':
      case 'delete_woo_controller_user_from_shop_failed':
      case 'create_woo_controller_user_in_shop_success':
      case 'create_woo_controller_user_in_shop_failed':
      case 'update_woo_controller_user_in_shop_success':
      case 'update_woo_controller_user_in_shop_failed':
      case 'delete_woo_controller_user_in_shop_success':
      case 'delete_woo_controller_user_in_shop_failed':
      case 'update_woo_controller_user_in_shop_succeed':
      case 'create_insert_customer_webhook_succeed':
      case 'create_insert_customer_webhook_failed':
      case 'create_update_customer_webhook_succeed':
      case 'create_update_customer_webhook_failed':
      case 'create_delete_customer_webhook_succeed':
      case 'create_delete_customer_webhook_failed':
        return this.$t(`logs.subject.users.${Object.values(data).length > 0 ? `${key}_dynamic` : key}`, data)

      case 'woo_controller_product_variant_technical_problem':
      case 'woo_controller_product_technical_problem':
      case 'delete_woo_controller_product_from_shop_failed':
      case 'delete_woo_controller_product_variant_from_shop_failed':
      case 'create_woo_controller_product_in_shop_success':
      case 'create_woo_controller_product_in_shop_failed':
      case 'update_woo_controller_product_in_shop_success':
      case 'update_woo_controller_product_in_shop_failed':
      case 'create_woo_controller_product_variant_in_shop_success':
      case 'create_woo_controller_product_variant_in_shop_failed':
      case 'update_woo_controller_product_variant_in_shop_success':
      case 'update_woo_controller_product_variant_in_shop_failed':
      case 'create_insert_product_webhook_succeed':
      case 'create_insert_product_webhook_failed':
      case 'create_update_product_webhook_succeed':
      case 'create_update_product_webhook_failed':
      case 'create_delete_product_webhook_succeed':
      case 'create_delete_product_webhook_failed':
      case 'update_woo_controller_product_stock_in_shop_succeed':
      case 'update_woo_controller_product_stock_in_shop_failed':
      case 'update_woo_controller_product_variant_stock_in_shop_succeed':
      case 'update_woo_controller_product_variant_stock_in_shop_failed':
      case 'update_woo_controller_product_price_in_shop_succeed':
      case 'update_woo_controller_product_price_in_shop_failed':
      case 'update_woo_controller_product_variant_price_in_shop_succeed':
      case 'update_woo_controller_product_variant_price_in_shop_failed':
      case 'deleted_woo_controller_product_from_shop':
      case 'woo_controller_product_transferred':
      case 'woo_controller_product_price_updated_from_shop':
      case 'woo_controller_product_stock_updated_from_shop':
      case 'woo_controller_product_variant_stock_updated_from_shop':
      case 'restore_woo_controller_product_in_shop_success':
      case 'restore_woo_controller_product_in_shop_failed':
      case 'woo_controller_product_updated_in_useral':
      case 'woo_controller_product_variant_updated_in_useral':
      case 'woo_controller_product_variant_transferred':
        return this.$t(`logs.subject.products.${Object.values(data).length > 0 ? `${key}_dynamic` : key}`, data)

      case 'create_woo_controller_attribute_value_in_shop_succeed':
      case 'create_woo_controller_attribute_value_in_shop_failed':
      case 'update_woo_controller_attribute_value_in_shop_succeed':
      case 'update_woo_controller_attribute_value_in_shop_failed':
      case 'delete_woo_controller_attribute_value_in_shop_succeed':
      case 'delete_woo_controller_attribute_value_in_shop_failed':
      case 'woo_controller_attribute_transferred':
        return this.$t(`logs.subject.attributes.${Object.values(data).length > 0 ? `${key}_dynamic` : key}`, data)

      default:
        return key
      }
    },
    getErrorLogs (log) {
      switch (log.key) {
      case 'useral_invoice_id':
      case 'invoice_user_not_received':
      case 'invoice_user_has_technical_problem':
      case 'invoice_user_not_existed_in_useral':
      case 'invoice_products_not_existed':
      case 'duplicated_invoice_number':
      case 'product_variant_technical_error':
      case 'product_variant_not_transfer_to_useral':
      case 'invoice_payment_not_existed':
      case 'empty_invoice_payment_number':
      case 'duplicated_invoice_payment_number':
      case 'delete_old_sale_invoices':
      case 'delete_old_purchase_invoices':
      case 'delete_old_return_sale_invoices':
      case 'delete_old_return_purchase_invoices':
        return this.$t(`logs.errors.invoices.${log.key}`, log)

      case 'useral_user_id':
      case 'empty_user_name':
      case 'empty_user_phone':
      case 'invalid_user_phone':
      case 'invalid_user_email':
      case 'duplicated_user_phone_in_useral':
      case 'duplicated_user_phone_in_shop':
      case 'duplicated_user_email_in_useral':
      case 'duplicated_user_email_in_shop':
        return this.$t(`logs.errors.users.${log.key}`, log)

      case 'invalid_product_manage_stock':
      case 'active_product_back_ordered':
      case 'empty_product_variants':
      case 'invalid_product_variant_attribute':
      case 'empty_product_variant_barcode':
      case 'duplicated_product_variant_barcode':
      case 'invalid_product_variant_barcode':
      case 'duplicated_product_code':
      case 'disable_product_variant_mange_stock':
      case 'active_product_variant_back_ordered':
      case 'empty_product_variant_attributes':
      case 'useral_product_id':
      case 'useral_product_variant_id':
        return this.$t(`logs.errors.products.${log.key}`, log)

      default:
        return log.key
      }
    }
  }
}
