// =============================================================================
// USERS PAGES LAYOUTS
// =============================================================================

export default {
  path: 'users',
  component: () => import('../../views/admin/users/index.vue'),
  children: [
    {
      path: '',
      name: 'users',
      component: () => import(/* webpackChunkName: "users" */ '../../views/admin/users/list/usersList.vue'),
      meta: {
        permission: 'user.show',
        pageTitle: 'اشخاص',
        pageTitleI18n: 'users.list.title'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'usersTrash',
      component: () => import(/* webpackChunkName: "usersTrash" */ '../../views/admin/users/trash/usersTrash.vue'),
      meta: {
        permission: 'user.delete',
        pageTitle: 'سطل زباله اشخاص',
        pageTitleI18n: 'users.trash.title'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertUser',
      component: () => import(/* webpackChunkName: "insertUser" */ '../../views/admin/users/insert/insertUser.vue'),
      meta: {
        permission: 'user.create',
        pageTitle: 'افزودن شخص',
        pageTitleI18n: 'users.insert.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      component: () => import('../../views/admin/users/user/index.vue'),
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "user" */ '../../views/admin/users/user/profile/userProfile'),
          name: 'user',
          meta: {
            permission: 'user.show',
            pageTitle: 'شخص',
            pageTitleI18n: 'users.profile.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'edit',
          name: 'editUser',
          component: () => import(/* webpackChunkName: "editUser" */ '../../views/admin/users/edit/editUser.vue'),
          meta: {
            permission: 'user.update',
            pageTitle: ',ویرایش اطلاعات شخص',
            pageTitleI18n: 'users.edit.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'transactions',
          name: 'userTransactions',
          component: () => import(/* webpackChunkName: "userTransactions" */ '../../views/admin/users/user/transactions/userTransactions.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'گردش مالی شخص',
            pageTitleI18n: 'users.transactions.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'points',
          name: 'userPoints',
          component: () => import(/* webpackChunkName: "userPoints" */ '../../views/admin/users/user/points/userPoints.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'امتیاز شخص',
            pageTitleI18n: 'users.points.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'max-debt',
          name: 'userMaxDebt',
          component: () => import(/* webpackChunkName: "userTransactions" */ '../../views/admin/users/user/maxDebt/setUserMaxDebt.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'گردش مالی شخص',
            pageTitleI18n: 'users.transactions.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'message',
          name: 'sendMessageToUser',
          component: () => import(/* webpackChunkName: "sendMessageToUser" */ '../../views/admin/users/user/chat/userChatRoom.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'گردش مالی شخص',
            pageTitleI18n: 'smsMessageInput.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'transactions/:docId',
          name: 'userTransaction',
          component: () => import(/* webpackChunkName: "userTransaction" */ '../../views/admin/accountancy/documents/document/document.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'سند گردش مالی شخص',
            pageTitleI18n: 'users.transactions.documentTitle',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'addresses',
          name: 'userAddresses',
          component: () => import(/* webpackChunkName: "userAddresses" */ '../../views/admin/users/user/addresses/userAddresses.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'شخص',
            pageTitleI18n: 'users.addresses.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'sale',
          name: 'insertUserSaleInvoice',
          component: () => import(/* webpackChunkName: "insertUserSaleInvoice" */ '../../views/admin/sales/invoices/insert/insertSaleInvoice.vue'),
          meta: {
            permission: 'sale_invoice.create',
            pageTitle: '',
            pageTitleI18n: 'sales.invoices.insert.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'purchase',
          name: 'insertUserPurchaseInvoice',
          component: () => import(/* webpackChunkName: "insertUserPurchaseInvoice" */ '../../views/admin/purchases/invoices/insert/insertPurchaseInvoice.vue'),
          meta: {
            permission: 'purchase_invoice.create',
            pageTitle: 'افزودن فاکتور خرید',
            pageTitleI18n: 'purchases.invoices.insert.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'receive',
          name: 'insertUserReceive',
          component: () => import(/* webpackChunkName: "insertUserReceive" */ '../../views/admin/treasury/receive/insert/insertReceive.vue'),
          meta: {
            pageTitle: 'دریافت',
            pageTitleI18n: 'treasury.receive.insert.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'payment',
          name: 'insertUserPayment',
          component: () => import(/* webpackChunkName: "insertUserPayment" */ '../../views/admin/treasury/payment/insert/insertPayment.vue'),
          meta: {
            pageTitle: 'پرداخت جدید',
            pageTitleI18n: 'treasury.payment.insert.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'cost',
          name: 'insertUserCost',
          component: () => import(/* webpackChunkName: "insertUserCost" */ '../../views/admin/treasury/cost/insert/insertCost.vue'),
          meta: {
            permission: 'cost.create',
            pageTitle: 'هزینه جدید',
            pageTitleI18n: 'treasury.cost.insert.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        },
        {
          path: 'activities-log',
          name: 'userActivitiesLog',
          component: () => import(/* webpackChunkName: "userActivitiesLog" */ '../../views/admin/users/user/activitiesLog/userActivitiesLog.vue'),
          meta: {
            permission: 'activity_logs.show',
            pageTitle: 'لاگ های شخص',
            pageTitleI18n: 'users.logs.title',
            layoutStatus: 'profile'
            // rule: 'editor'
          }
        }
      ]
    }
  ]
}
